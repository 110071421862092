<template>
	<div>
		<!-- 详细区域 -->
		<el-dialog title="详细区域" :visible.sync="showModel" class="myMap" width="70%" :close-on-click-modal="false"
			:close-on-press-escape="false" :before-close="handleClose">
			<span slot="footer" class="dialog-footer">
				<div id="container" :style="{height: '450px', width: '100%'}"></div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			showModel: {
				type: Boolean,
				default: false
			},
			myMapTypeID: {
				type: Number,
				default: ""
			}
		},
		data() {
			return {
				district: '',
				opts: {
					subdistrict: 0, // 获取边界不需要返回下级行政区
					extensions: 'all', // 返回行政区边界坐标组等具体信息
					level: 'district' // 查询行政级别为 市
				},
				polygonData: [],
				map: {},
				myarr: [],
				allFence: []
			}
		},
		mounted() {
			if (this.showModel == true) {
				this.transNo()
			}

		},
		methods: {
			// 显示地图
			transNo() {
				const that = this
				this.map = new AMap.Map("container", {
					resizeEnable: true,
					center: [87.616842, 43.82539], // [87.616842, 43.82539], //初始地图中心点
					zoom: 12, //初始地图级别
					animateEnable: true
				});
				this.district = new AMap.DistrictSearch(this.opts, this.showbiz)
				this.showFence = true
				that.polygonData = []
				this.ApiRequestPostNOMess('/api/mall/ebmonitor/sale-area-fence/get-list-area-async-hd', {
					fAreaID: this.myMapTypeID
				}).then(res => {
					that.myarr = []
					that.myarr = res.obj.items
					for (let item = 0; item < that.myarr.length; item++) {
						console.log(that.myarr[item])
						that.polygonData = that.myarr[item].detailhd;
						if (that.polygonData.length == 0 && that.myarr[item].fIsUseCity == 1) {
							const adcode = that.myarr[item].fGovernmenAreaCode
							that.district.search(adcode, (status, result) => {
								var bounds = result.districtList[0].boundaries
								let path1 = bounds[0]
								const arr = []
								for (let z = 1; z < bounds.length; z++) {
									if (bounds[z].length > path1.length) {
										path1 = bounds[z]
									}
								}
								for (let j = 0; j < path1.length; j++) {
									const obj = []
									obj.push(path1[j].lng)
									obj.push(path1[j].lat)
									arr.push(obj)
								}
								const polygonss = []
								const center = that.calculateCenter(arr)
								for (let x = 0; x < bounds.length; x++) {
									const polygon = new AMap.Polygon({
										trokeWeight: 1,
										path: bounds[x],
										zIndex: that.myarr[item].fGovernmenAreaCode * 1,
										fillOpacity: 0.4,
										fillColor: '#80d8ff',
										strokeColor: '#0091ea',
										extData: {
											fFenceID: that.myarr[item].fFenceID,
											fFence: that.myarr[item].fFence,
											fFenceCode: that.myarr[item].fFenceCode,
											frv: that.myarr[item].frv,
											item: that.myarr[item]

										}
									})
									polygonss.push(polygon)
									that.allFence.push(polygon)
									polygon.on('click', function(e) {
										if (that.type === 'circle' || that.type === 'polygon') {
											return
										}
										that.defaultActive = polygon._opts.extData.fFenceCode
										if (that.activeEnclosure !== '' && that.active ===
											'edit') {
											that.$message.warning('请先结束编辑再去操作')
											return
										}
										const polygons = that.map.getAllOverlays('polygon')
										for (var i = 1; i < polygons.length; i++) {
											polygons[i]._opts.fillColor = '#80d8ff'
										}
										const circles = that.map.getAllOverlays('circle')
										for (var i = 0; i < circles.length; i++) {
											circles[i]._opts.fillColor = '#80d8ff'
										}
										polygon.setOptions({
											fillColor: '#1E9FFF',
											fillOpacity: 0.8
										})
										that.delEnclosureList = [{
											fFenceID: e.target._opts.extData.fFenceID,
											frv: e.target._opts.extData.frv
										}]
										that.enclosureName = e.target._opts.extData.fFence
										that.activeEnclosure = polygon
										that.EnclosureType = 2
										if (that.active === 'edit') {
											that.showEditEnclosure()
										} else if (that.active === 'delOne') {
											that.delEnclosure = true
										}
									})
								}
								that.map.add(polygonss)
								that.map.setFitView()
								const textInfo = new AMap.Text({
									text: `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`,
									style: {
										'background-color': 'rgba(255,255,255,.5)',
										'color': 'red'
									},
									position: center,
									zIndex: 20,
									extData: {
										fFenceID: that.myarr[item].fFenceID
									}
								})
								that.map.add(textInfo)
							})
						}
						for (let i = 0; i < that.polygonData.length; i++) {
							const fence = {}
							fence.id = that.myarr[item].fFenceCode
							fence.name = `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`
							if (that.myarr[item].fFenceTypeID === 1) {
								const circle = new AMap.Circle({
									center: [that.polygonData[i][0].flng, that.polygonData[i][0].flat],
									radius: that.polygonData[i][0].fRadius, // 半径
									trokeWeight: 1,
									fillOpacity: 0.4,
									zIndex: 1000000,
									fillColor: '#80d8ff',
									strokeColor: '#0091ea',
									extData: {
										fFenceID: that.myarr[item].fFenceID,
										fFence: that.myarr[item].fFence,
										fFenceCode: that.myarr[item].fFenceCode,
										frv: that.myarr[item].frv,
										item: that.myarr[item]
									}
								})
								that.map.add(circle)
								that.allFence.push(circle)
								const textInfo = new AMap.Text({
									text: `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`,
									zIndex: 21,
									style: {
										'background-color': 'rgba(255,255,255,.5)',
										'color': '#0091ea'
									},
									position: [that.polygonData[i][0].flng, that.polygonData[i][0].flat],
									extData: {
										fFenceID: that.myarr[item].fFenceID
									}
								})
								that.map.add(textInfo)
								that.map.setFitView(textInfo)
								// }

							} else {
								let arr = []
								const path = []
								if (that.myarr[item].fIsUseCity === 1) {
									const adcode = that.myarr[item].fGovernmenAreaCode
									that.district.search(adcode, (status, result) => {
										var bounds = result.districtList[0].boundaries
										let path1 = bounds[0]
										const arr = []
										for (let z = 1; z < bounds.length; z++) {
											if (bounds[z].length > path1.length) {
												path1 = bounds[z]
											}
										}
										for (let j = 0; j < path1.length; j++) {
											const obj = []
											obj.push(path1[j].lng)
											obj.push(path1[j].lat)
											arr.push(obj)
										}
										const polygonss = []
										const center = that.calculateCenter(arr)
										for (let x = 0; x < bounds.length; x++) {
											const polygon = new AMap.Polygon({
												trokeWeight: 1,
												path: bounds[x],
												zIndex: this.polygonData[i].fGovernmenAreaCode * 1,
												fillOpacity: 0.4,
												fillColor: '#80d8ff',
												strokeColor: '#0091ea',
												extData: {
													fFenceID: that.myarr[item].fFenceID,
													fFence: that.myarr[item].fFence,
													fFenceCode: that.myarr[item].fFenceCode,
													frv: that.myarr[item].frv,
													item: that.myarr[item]
												}
											})
											polygonss.push(polygon)
											that.allFence.push(polygon)
										}
										that.map.add(polygonss)
										that.map.setFitView()
										const textInfo = new AMap.Text({
											text: `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`,
											style: {
												'background-color': 'rgba(255,255,255,.5)',
												'color': 'red'
											},
											position: center,
											zIndex: 20,
											extData: {
												fFenceID: that.myarr[item].fFenceID
											}
										})
										that.map.add(textInfo)
									})
								} else {
									arr = that.polygonData[i]
									arr.forEach(item => {
										const obj = []
										obj.push(item.flng)
										obj.push(item.flat)
										path.push(obj)
									})
									const polygon = new AMap.Polygon({
										trokeWeight: 1,
										path: path,
										fillOpacity: 0.4,
										fillColor: '#80d8ff',
										strokeColor: '#0091ea',
										zIndex: 1000000,
										extData: {
											fFenceID: that.myarr[item].fFenceID,
											fFence: that.myarr[item].fFence,
											fFenceCode: that.myarr[item].fFenceCode,
											frv: that.myarr[item].frv,
											item: that.myarr[item]
										}
									})
									that.map.add(polygon)
									that.allFence.push(polygon)
									const center = that.calculateCenter(path)
									const textInfo = new AMap.Text({
										text: `${that.myarr[item].fFence}(编号${that.myarr[item].fFenceCode})`,
										style: {
											'background-color': 'rgba(255,255,255,.5)',
											'color': '#0091ea'
										},
										position: center,
										zIndex: 21,
										extData: {
											fFenceID: that.myarr[item].fFenceID
										}
									})
									that.map.add(textInfo)
								}
							}
							that.map.setFitView()
						}
					}
				})
			},
			// 获取多变行中心坐标
			calculateCenter(lnglatarr) {
				var total = lnglatarr.length;
				var X = 0
				var Y = 0
				var Z = 0
				lnglatarr.map((item) => {
					var lng = (item[0] * Math.PI) / 180;
					var lat = (item[1] * Math.PI) / 180;
					var x, y, z;
					x = Math.cos(lat) * Math.cos(lng);
					y = Math.cos(lat) * Math.sin(lng);
					z = Math.sin(lat);
					X += x;
					Y += y;
					Z += z;
				});
				X = X / total;
				Y = Y / total;
				Z = Z / total;
				var Lng = Math.atan2(Y, X);
				var Hyp = Math.sqrt(X * X + Y * Y);
				var Lat = Math.atan2(Z, Hyp);
				return ([(Lng * 180) / Math.PI, (Lat * 180) / Math.PI]);
			},
			handleClose() {
				this.$emit('change', !this.showModel)
			},
		}
	}
</script>

<style scoped>
	.myMap {
		.el-dialog__body {
			padding: 0;
		}
	}
</style>
