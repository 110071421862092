<!-- 商品详情 -->
<template>
	<div :class="themeClass" class="max-width1100 margin-tb-20" id="shopCar">
		<div class="flex-row margin-b-5">
			您的位置：
			<breadcrumb></breadcrumb>
		</div>
		<div class="flex-colum">
			<div class="flex-row top-box">
				<div class="box-left margin-r-20">
					<div class="outline-imgs position-relative">
						<div class="position-relative border-F2F2F2" style="width: 347px;height: 347px;"
							v-if="videoShow">
							<div class="position-absolute iconfont icon-guanbi font-size30 font-weight700 delete-btn pointer"
								@click="videoShow = false"></div>
							<video ref="myvideo" controls="controls" :autoplay="videoShow?'autoplay':''"
								style="width: 100%;height: 100%;">
								<source
									:src="listData.goodsVideos ? listData.goodsVideos[0].fPath == '' ? null : listData.goodsVideos[0].fullFPath : null" />
							</video>
						</div>
						<div class="outline-img position-relative" v-else>
							<piczoom v-show="!listData.goodsVideos" :url="showPicUrl.fPicturePath" :scale="3" />
							<img v-show="listData.goodsVideos && listData.goodsVideos[0].fPath != ''"
								:src="showPicUrl.fPicturePath" alt="">
							<div v-if="listData.goodsVideos && listData.goodsVideos[0].fPath != ''"
								class="position-absolute font-size48 font-color-fff play-img"
								style="bottom: 50px;left: 43.5%;opacity: 1;" @click="videoShow = true"></div>
						</div>
						<div class="outline-piclist">
							<div class="left-arrow margin-t-20" v-if="picurl.length > 1" @click="leftImg">◀</div>
							<div class="right-arrow margin-t-20" v-if="picurl.length > 1" @click="rightImg">▶</div>
							<div class="piclist-box" ref="piclist">
								<div class="piclist-img" v-for="(value,index) in picurl" :key="index"
									:class="{'active':showPicUrl.fPicturePath == value.fPicturePath}"
									@click="imgClick(value,index)">
									<img :src="value.fPicturePath" />
								</div>
							</div>
						</div>
						<div v-if="hasShowAl" class="position-absolute activity-img-box"
							:class="{'iconTopRight': activityInfo.fDispArea==0, 'iconBottomRight': activityInfo.fDispArea==1, iconBottomLeft: activityInfo.fDispArea==2,iconTopLeft:activityInfo.fDispArea==3}">
							<img class="activityImg position-absolute"
								:class="{'iconTopRight': activityInfo.fDispArea==0, 'iconBottomRight': activityInfo.fDispArea==1, iconBottomLeft: activityInfo.fDispArea==2,iconTopLeft:activityInfo.fDispArea==3}"
								:src="activityInfo.fIconPath" />
						</div>
						<!--是否已售罄-->
						<div v-if='listData.fStatus == 2 ? true:false' class='box-sellout'>
							<img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
						</div>
					</div>
				</div>
				<div class="box-right flex-colum">
					<div class="flex-row margin-b-5">
						<div class="tag-box font-size12 font-color-fff radius2 margin-r-5"
							v-if="listData.fShopTypeID == 1">
							{{listData.fShopTypeName}}
						</div>
						<div class="font-size18 font-weight700" style="line-height: 18px;">{{listData.fGoodsFullName}}
						</div>
					</div>

					<!-- 活动条幅 -->
					<div v-if="hasActivity" class="activity-box flex-row-space-between  font-color-fff font-size18"
						style="background: #E1251A;" :style="hasActivity?'border-radius:10px 10px 0 0;':''">
						<div style="margin: 0 13px;width:100%" :style="hasActivity?'border-bottom:1px dashed #fff':''"
							class='flex-row-space-between'>
							<div class="flex-row-align-center">
								<div class="font-weight700">
									{{activityInfo.fEventName}}
								</div>
							</div>
							<div class="flex-row-align-center font-size12">
								<span class="margin-r-10 font-size16">{{showactivitytext}}</span>
								<countDowm :remainTime="usedTime" :showBold='false' @countDownEnd="countDownEnd"
									bgColor="#ffffff"></countDowm>
							</div>
						</div>
					</div>
					<div class="commodityInformation-box  font-size13 bg-FFFAF9 font-color-666  padding-lr-13  "
						style=""
						:class="hasActivity?'activityBagBox padding-tb-5 ':'StandardBcgBox  padding-tb-10 margin-t-15'">
						<div class="flex-row-space-between">
							<div class="flex-row-space-between" style="width:100%">
								<div class="flex-row-space-between-center">
									<div class="tatle-box font-color-999 font-size13"
										:style="hasActivity?'color:#fff':'color:#1E1E1E'">
										现价
									</div>
									<span class="margin-r-10 font-size25 font-weight700 font-color-FF0000"
										:style="hasActivity?'color:#fff':''">
										<span v-if='true' class="font-size20">¥</span>
										<span
											v-if='fSmallMinPrice == priceRange ? false:true'>{{fSmallMinPrice|NumFormat}}~</span>
										{{priceRange|NumFormat}}
										<span class="font-size20"
											:style="hasActivity?'font-size:13px;color:#fff':'color:#1E1E1E;font-weight:400;font-size:13px'">元/{{listData.fUnitName}}
										</span>
									</span>
								</div>
								<div @click="star" class="flex-row-space-between-center">
									<div v-if="collection">
										<span
											class="iconfont icon-wujiaoxingxingxingshoucangmoren margin-r-5 font-color-FF0000 font-size13"
											:style="hasActivity?'color:#fff':''"></span>
										<span :style="hasActivity?'color:#fff':''">收藏成功</span>
									</div>
									<div v-else>
										<span class="iconfont icon-wujiaoxing margin-r-5 font-color-FF0000 font-size13"
											:style="hasActivity?'color:#fff':''"></span>
										<span :style="hasActivity?'color:#fff':''">收藏商品</span>
									</div>
								</div>
							</div>
							<div class="flex-row">
							</div>
						</div>
						<div class="flex-row" v-if="listData.fActivityIntegralMultiple > 1"
							:style="hasActivity?'color:#fff':''">
							<div class="tatle-box font-color-999" :style="hasActivity?'color:#fff':''">
								活动
							</div>
							<span class="font-color-FF0000 margin-r-5"
								:style="hasActivity?'color:#fff':''">赠</span>{{listData.fActivityIntegralMultiple}}倍积分
						</div>
						<div class="flex-row">
							<div :class="changeStyle">
								<div class="flex-row" v-if="couponShow">
									<div class="tatle-box">
									</div>
									<span class="font-color-FF0000 margin-r-5 pointer"
										style="border-bottom: 1px solid #FF0000;"
										:style="hasActivity?'color:#fff;border-bottom:1px solid #fff':''"
										@click="toCouponCollection">去领取优惠券></span>

								</div>
							</div>

							<div v-if="CheckGoodsShow ==1">
								<span class="margin-r-5 pointer"
									style="border-bottom: 1px solid #0173FE;color: #0173FE;"
									:style="hasActivity?'color:#fff;border-bottom:1px solid #fff':''"
									@click="toTask">本商品已参与任务集市奖励活动></span>
							</div>
						</div>

						<div class="margin-b-10 flex-row-right">

						</div>
						<div class="margin-l-65 font-color-FA2C60 margin-b-10 flex-row"
							v-for="(item,index) of collectCouponsPopupData" :key="index">
							<!--  v-for="(item,index) of listData.couponlist" :key="index" -->
							<div class="margin-r-10">
								店铺<span class="dashed"></span>优惠券
							</div>
							<div class="margin-r-20 font-color-666">
								{{item.fCouponName}}
							</div>
							<div class="receive-box pointer" v-if="item.fReceiveStatus == 0"
								@click="receiveClick(item.fReceiveStatus,item.fCouponID)">
								领取
							</div>
							<div class="font-color-666" v-else>
								已领取
							</div>
						</div>
					</div>
					<div class="padding-t-13 font-color-666 commodityInformation-box">
						<div class="flex-colum font-size13">
							<div class="flex-row margin-b-13 padding-lr-13" v-for="(item,index) of saleAttributeList"
								:key="index">
								<div class="font-color-1e1e1e padding-tb-5 tatle-box flex-row-space-between-center">
									{{item.fClassPropertie}}
								</div>
								<div class="margin-r-15 font-color-000"
									v-for="(item1,index1) of item.goodsClassPropertieDatas" :key="index1">
									<div class="factory-box padding-5 border-F2F2F2 text-center pointer-color-theme factory-hover"
										:class="item1.checked?'bcgEAF3FF border-theme color-theme':''"
										@click="saleAttributeClick(item.fClassPropertieID,item1.fClassPropertieDataID)">
										{{item1.fClassPropertieData}}
									</div>
								</div>
							</div>
							<div class="flex-row margin-b-13 padding-lr-13" v-for="(item,index) of otherAttributeList"
								:key="item.fClassPropertieID">
								<div class="font-color-1e1e1e padding-tb-5 tatle-box flex-row-space-between-center">
									{{item.fClassPropertie}}
								</div>
								<div class="margin-r-15 font-color-000"
									v-for="(itemDetailed,detailedIndex) of item.detailed"
									:key="itemDetailed.fGoodsPropertieDataID">
									<div class="factory-box padding-5 border-F2F2F2 text-center pointer-color-theme factory-hover"
										:class="itemDetailed.checked?'bcgEAF3FF border-theme color-theme':''"
										@click="otherAttributeClick(item.fClassPropertieID,itemDetailed.fGoodsPropertieDataID)">
										{{itemDetailed.fGoodsPropertieData}}
									</div>
								</div>
							</div>
							<div class="flex-row margin-b-13 padding-lr-13">
								<div class="font-color-1e1e1e padding-tb-5 tatle-box flex-row-space-between-center">
									<div>
										数量
										<span v-if="listData.fUnitName">({{listData.fUnitName}})</span>
									</div>
								</div>
								<div class="flex-row-wrap width-fill">
									<el-input-number v-model="numValue" @change="handleChange" :min="minNumValue"
										:max="99999" :precision="precisionNumValue" :step="stepNumValue" label="描述文字"
										@focus="getInputFocus($event)"></el-input-number>
								</div>
							</div>
							<div style="width:730px"
								class="flex-row-space-between bg-FAFAFA address-box box-sizing padding-tb-5 padding-lr-15 margin-b-15">
								<div class="margin-b-5 font-color-000">
									{{listData.fDepotName}}仓库地址：<span class='font-color-666'>{{warehouseAddress}}</span>
								</div>
								<div class="flex-row-space-between font-color-999">

									<div class="pointer-color-theme" @click="toMap(warehouseAddressLatAndLng)">地图></div>
								</div>
							</div>
							<!-- 门店信息 -->
							<shopList v-if="showShopList && (listData.fShopUnitID == 5 || isThird)"
								:ShopGoodID="listData.fGoodsID" :needFShopUnitID="listData.fShopUnitID"
								:ShopList="ShopList" @change="shopListChange"></shopList>
							<!-- 定向送达活动 -->
							<div class="activity" v-if="isShowActivity">
								<div class="tit font-size14">
									<div class="img"></div> 定向送达活动
								</div>
								<div class="btn" @click="showActivity()">点击参与 ></div>
								<div class="activityInfo" v-show="JSON.stringify(radioActivity) !== '{}'">
									在活动时间<span>{{radioActivity.activityTime}}</span>内，使用装载<span>{{radioActivity.fIsHdTitle}}</span>的车辆，将货物以<span>{{radioActivity.fDeliveryNames}}</span>的方式
									<span
										v-if="radioActivity.fMaxMins !=0">在<span>{{radioActivity.fMaxMins}}</span>分钟内</span>
									<span v-if="radioActivity.fMaxMins ==0">不限时间</span>
									送到<span>{{radioActivity.fFence}}</span>奖励<span>{{radioActivity.integral}}</span>
								</div>
							</div>
							<div class="flex-row margin-b-50" v-if="listData.fGoodsClassID != '3'">
								<div v-show="fSmallMinPrice"
									class="btn-box background-color-theme radius30 text-center margin-r-10 pointer font-size18 font-weight700"
									@click="addToCart">
									加入购物车
								</div>
								<div v-show="fSmallMinPrice"
									class="btn-box radius30 text-center margin-r-10 bg-FF9900 font-color-fff pointer font-size18 font-weight700"
									@click="buyNowClick(listData)">
									立即购买
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="bottom-box font-size13 flex-row">
				<div class="b-box-left margin-r-10">
					<div class="left-top flex-colum border-F2F2F2 margin-b-10 padding-13">
						<div class="flex-row margin-b-5">
							<div class="img-box border-F2F2F2 radius10 padding-5 margin-r-5">
								<img :src="listData.fShopLogoPath" alt="" class="img-style">
							</div>
							<div class="flex-colum width-fill">
								<div class="font-weight700 font-size14 twoline-show-hide margin-b-5">
									{{listData.fShopName}}
								</div>
								<div
									class="shopStarRating-box radius20 flex-row bg-F2F2F2 text-center font-size12 padding-lr-10 margin-b-5">
									<span class="margin-r-5">
										店铺星级
									</span>
									<div class="margin-tb-auto">
										<el-rate v-model="starLevel" :colors="['#FF3300','#FF3300','#FF3300']"
											:disabled-void-color="'#e4e4e4'" disabled></el-rate>
									</div>
								</div>
								<div class="flex-row-space-between-center margin-b-5">
									<div>{{focusOnQuantity}}关注</div>
									<div class="bg-F8F9FE radius30 color-theme pointer follow-box text-center"
										@click="followCreateClick" v-if="followShow">+关注</div>
									<div class="bg-F8F9FE radius30 color-theme pointer follow-box text-center"
										@click="followDeleteClick" v-else>已关注</div>
								</div>
							</div>
						</div>

						<div class="flex-row-space-between">
							<div v-if="fRulObj !=null " class="shopBtn-box text-center border-theme radius30 pointer"
								@click="contactServiceClick">
								<span class="iconfont icon-kefu1 margin-r-5 color-theme font-size17"></span>
								联系客服
							</div>
							<div class="shopBtn-box text-center border-theme radius30 pointer"
								@click="enterTheStore(listData.fShopUnitID)">
								<span class="iconfont icon-dianpu2 margin-r-5 color-theme"></span>
								进店逛逛
							</div>
						</div>
					</div>
					<div class="left-bottom bg-F2F4FD box-sizing padding-13 flex-row-between-wrap"
						v-if="shopMerchandise.length>0">
						<div class="pointer" :class="index+1 == 1?'margin-b-10':''"
							v-for="(item,index) of shopMerchandise" :key="index" @click="hotGoods(item)">
							<div class="img-box1 bg-fff radius10 flex-row-center-center">
								<img :src="item.goods.fPicturePath" alt="" class="img-style1 margin-auto">
							</div>
							<div class="title-box hide-text margin-t-10 font-size12">
								{{item.goods.fGoodsFullName}}
							</div>
							<div class="title-box hide-text margin-t-10 font-size14 font-color-FF0000 font-weight700">
								<span class="font-size12">￥</span>{{item.fMinGoodsPrice|NumFormat}}<span
									class="font-size12">元/{{listData.fUnitName}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="b-box-right">
					<div class="left-middle flex-colum border-F2F2F2 margin-b-10 padding-13"
						v-if="ruledetails.length>0">
						<div class="flex-row margin-b-10">
							<img src="@/assets/imgs/rules.png" alt="" style="width:28px;height:28px;">
							<div class="font-weight700  font-size18 margin-l-10" style="color:#0173FE">店铺规则</div>
							<div class="font-weight400 font-size18 margin-l-10" style="color:#0173FE">SHOP&nbsp;RULES
							</div>
						</div>
						<div class="padding-10 bg-fff radius5" style="box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.10); ">
							<div v-for="(item,index) of ruledetails" :key="index">
								<div class="flex-colum">
									<div v-if="item.fIsTitle==2" class="color-theme font-weight700"
										style="line-height: 22px; color: #0173FE;">
										{{item.fValue}}
									</div>
									<div v-if="item.fIsTitle==1" class="font-size12 font-weight700"
										style="line-height: 18px;color: #000000;">
										{{item.fValue}}
									</div>
									<div v-if="item.fIsTitle==0" class="font-size12" style="line-height: 18px;">
										{{item.fValue}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="">
						<el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
							<el-tab-pane v-for="(item,index) of tabData" :key="index" :label="item.fGoodsDetailClass"
								:name="item.fGoodsDetailClassCode">
								<div class="border-F2F2F2 padding-20">
									<RichTextStyle v-if="item.fContent != null" :html="item.fContent"
										:styleRule="rule" />
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</div>



		<el-dialog title="定向送达活动" :visible.sync="isShowActivityDailog" :close-on-click-modal="false" width="60%">
			<div class="margin-l-10">
				<span>定向送达活动中</span>
				<span class="OrderText">使用GPS设备的车辆在抵达目的地后需上传车辆信息</span>
				<span>，未上传信息的派车单将无法赠送积分(使用汉德设备无需上传)。</span>
				<a style="color:#0173FE;OrderA" target="_blank"
					href="https://www.xjjcjc.com/helpcenter/FenceArrive.html">(点击查看GPS设备车辆上传操作流程)</a>
			</div>
			<el-table :header-cell-style="{background:'#F8F9FE'}" class="margin-b-10 margin-t-10 margin-lr-15"
				:data="tableData" size="mini" border>
				<el-table-column label="选择" align="center" fixed width="55">
					<template slot-scope="scope">
						<el-radio v-model="radioIdSelect" :label="scope.row.fRuleID"
							@change.native="handleSelectionChange(scope.row)"
							@click.native="clickRadio($event, scope.row)">{{str}}</el-radio>
					</template>
				</el-table-column>
				<el-table-column align="left" label="送达目的地" fixed width="150">
					<template slot-scope="scope">
						<el-button type="text" class="fence"
							@click="showFencveInfo(scope.row)">{{scope.row.fFence}}</el-button>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="fDeliveryNames" label="提货方式" width="160">
				</el-table-column>
				<el-table-column align="center" label="运输时长(分钟)" width="200">
					<template slot-scope="scope">
						<template v-if="scope.row.fMaxMins !=0">
							<span>{{scope.row.fMaxMins}}</span>
							<hr>
							<span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 出厂时间至抵达目的地的时长</span>
						</template>
						<template v-if="scope.row.fMaxMins ==0">
							<span>不限时间</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="活动时间">
					<template slot-scope="scope">
						<span>{{scope.row.fBeginTime}}</span>
						<hr>
						<span>至</span>
						<hr>
						<span>{{scope.row.fEndTime}}</span>
						<hr>
						<span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 以送达时间为准</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="integral" label="赠送积分" width="120">
				</el-table-column>
				<el-table-column align="center" prop="fIsHdName" label="设备" width="100">
				</el-table-column>
				<el-table-column label="" width="160" align="center">
					<template slot-scope="scope">
						<el-button @click.native.prevent="showFencveInfo(scope.row)" type="primary" size="small">
							查看目的地范围
						</el-button>
					</template>
				</el-table-column>
			</el-table>

			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="uncheckedActivity">取消选中</el-button>
				<el-button type="primary" @click="checkedActivity">确认选中</el-button>
			</span>
		</el-dialog>
		<myMapType v-if="myMapTypeVisible" :showModel="myMapTypeVisible" :myMapTypeID="myMapTypeID"
			@change="myMapTypeChange"></myMapType>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue'
	import countDowm from '@/components/countDown/countDown.vue'
	import RichTextStyle from "@/components/richtext/RichTextStyle";
	import pagination from '@/components/pagination/pagination.vue'
	import selectArea from '@/components/selectArea/selectArea.vue'
	import config from '../../../../config/config.json';
	import myMapType from '@/components/myMapType.vue';
	import shopList from '@/components/shoplist/shopList.vue' // 门店信息
	import piczoom from '@/components/vue-piczoom/'
	export default {
		components: {
			breadcrumb,
			countDowm,
			RichTextStyle,
			pagination,
			selectArea,
			myMapType,
			shopList,
			piczoom
		},
		data() {
			return {
				isThird: false, // 第三方店铺进入不显示门店
				showShopList: false, // 是否显示门店
				myMapTypeVisible: false, //控制地图显示弹窗的显示与隐藏
				myMapTypeID: "",
				// 定向送达活动奖励数据
				isShowActivityDailog: false,
				isShowActivity: false,
				getActivity: {},
				str: '',
				radioIdSelect: -1,
				fDeliveryIDs: '', // 提货方式
				activeActivity: {},
				tableData: [],
				map: {},
				showFence: false,
				district: '',
				opts: {
					subdistrict: 0, // 获取边界不需要返回下级行政区
					extensions: 'all', // 返回行政区边界坐标组等具体信息
					level: 'district' // 查询行政级别为 市
				},
				showbiz: false,
				polygonData: [],
				fUnitNames: '',
				radioActivity: {},


				changeStyle: 'flex-row-space-between',
				videoShow: false, //商品视频介绍是否展示
				isCollection: true, //控制收藏点击
				popoverShow: false, //配送地Popover 弹出框是否弹出

				collectCouponsPopupData: [], //优惠券信息
				addressActiveName: 'first', //默认显示哪个tab

				fGoodsID: 1, //当前商品id
				fDeliveryID: '', //当前商品选中的提货方式id
				numValue: null, //商品数量
				minNumValue: null, //计数器可输入的最小值
				stepNumValue: null, //计数器步长
				precisionNumValue: null, //计数器精度
				showPicUrl: {
					fPicturePath: ''
				}, //商品主图
				picurl: [], //商品左右图片
				showPicInx: 0, // 当前选中商品图片下标
				priceRange: '', //价格区间
				listData: {}, //商品详情数据
				collection: false, //是否收藏
				evaluateNum: '100+',
				highPraise: '90%',
				countDownShow: true,
				saleAttributeList: [], //商品销售属性

				otherAttributeList: [], //商品其他销售属性

				deliveryMethodList: [], //提货方式

				warehouseAddress: '', //仓库地址
				warehouseAddressLatAndLng: {}, //仓库所有信息包含仓库地址经纬度

				attributeGoods: [], //判断商品是否存在如不存在则显示无货接口除了其他属性以外属性参数数组
				otherattributeID: '', //判断商品是否存在如不存在则显示无货其他属性参数
				isItAvailable: true, //是否有货

				focusOnQuantity: '', //店铺多少人关注
				followShow: false, //是否关注
				starLevel: null, //店铺星级
				shopMerchandise: [], //店铺热销商品

				tabData: [], //厂家，规格，适用范围，售后保障
				activeName: '', //选中哪个
				content: '',
				rule: [], //富文本规则
				thumbActive: false, //评价是否点赞

				total: 1000, //总条数
				pagesize: 10, //每页显示条目个数
				currentPage: 1, //当前页

				showModel: false, //打开议价申请
				form: {
					danhao: '',
					startDate: '',
					didian: '',
					yuanyin: '',
					fRemark: ''
				},
				rules: {
					didian: [{
						required: true,
						message: '地点不能为空',
						trigger: 'blur'
					}],
				},
				formLabelWidth: '100px', //label宽
				hasActivity: false, //是否有活动
				hasShowAl: false, //是否有活动
				activityInfo: {}, //活动内容
				usedTime: 0, //倒计时的毫秒数
				showactivitytext: "",
				fIsActivity: 0, //是否参与活动0未参与1参与
				couponShow: false,
				fIsEnablePoint: 0, //是否参与积分活动 0未参与 1参与
				fSmallMinPrice: '',
				ruledetails: [],
				acsData: {},
				acsDataimmediately: {},
				fRulObj: null,
				CheckGoodsShow: 0,
				browserInfo: {},
				ShopList: [],
				fGovCode: '',
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		created() {
			this.browserInfo = this.getBrowserInfo();
		},
		mounted() {
			this.$store.commit("changeGoodsDetailsIsGoodsData", this.$route.query.detailobj);
			this.$refs.piclist.style.left = 48 + "px";
			this.fGoodsID = this.$route.query.fGoodsID;
			this.getParticulars()
			this.getAttentionCollection()
			if (sessionStorage.getItem('district')) {
				let district = JSON.parse(sessionStorage.getItem('district'))
				this.fGovCode = district.code
			} else {
				let city = JSON.parse(sessionStorage.getItem('city'))
				this.fGovCode = city.code
			}
			// this.getGodPro()
			this.getGoodsAttentionCollection(this.fGovCode)
		},
		methods: {
			getBrowserInfo() {
				var Sys = {};
				var ua = navigator.userAgent.toLowerCase();
				var re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/;
				var m = ua.match(re);
				Sys.browser = m[1].replace(/version/, "'safari");
				Sys.ver = m[2];
				return Sys;
			},
			// 整合接口
			getGoodsAttentionCollection(val) {
				this.ApiRequestPostNOMessV7('/api/mall/client/goods/get-attention-collection', {
						FGoodsID: this.fGoodsID,
						FLNG: 0,
						FLAT: 0,
						adcode: val,
						FAppTypeID: 2,
						FBrowser: this.browserInfo.browser,
						Version: this.browserInfo.ver,
					}).then(res => {
						// 商品信息
						let FGoods = this.convertKeysToLowercase(res.Obj.FGoods)
						this.showShopList = true
						this.videoShow = false;
						this.picurl = [];
						this.listData = FGoods;
						if (this.listData.fStatus == 2) {
							this.hasActivity = false
							this.hasShowAl = false;
						}
						// 店铺星级
						let sums = FGoods.fEvaluationScore;
						if (sums != null) {
							this.starLevel = sums / 20;
						}
						this.fIsEnablePoint = FGoods.fIsEnablePoint; //是否参与积分活动
						this.showPicUrl.fPicturePath = FGoods.fPicturePath;
						FGoods.goodsPictures.unshift(this.showPicUrl);
						this.picurl = FGoods.goodsPictures;
						this.getActivity.fGoods = FGoods.fGoodsID
						this.getActivity.fDepotID = FGoods.fDepotID
						this.fUnitNames = FGoods.fUnitName
						this.otherAttributeList = [];
						for (let item of FGoods.goodsClassProperties) {
							let data = {
								fClassPropertie: item.fClassPropertie,
								fClassPropertieID: item.fClassPropertieID,
								detailed: []
							}
							for (let pitem of FGoods.goodsPropertieDataDtos) {
								if (pitem.fClassPropertieID == item.fClassPropertieID) {
									let detailed = {
										checked: false,
										fGoodsPropertieData: pitem.fGoodsPropertieData,
										fGoodsPropertieDataID: pitem.fGoodsPropertieDataID
									}
									data.detailed.push(detailed)
								}
							}
							if (data.detailed.length > 0) {
								data.detailed[0].checked = true;
								this.otherAttributeList.push(data);
							}
						}
						// 价格相关信息
						let FPic = this.convertKeysToLowercase(res.Obj.FPic)
						if (FPic.length > 0) {
							let data = FPic[0];
							this.priceRange = data.fGoodsPrice;
							this.fSmallMinPrice = data.fGoodsMinPrice;
							this.numValue = data.fMinSaleAmount; //最小销量
							this.minNumValue = data.fMinSaleAmount; //最小销量
							this.stepNumValue = data.fAdjustmentRange; //调整幅度
							this.precisionNumValue = data.fPrecision; //精度
						} else {
							this.isItAvailable = false;
						}
						// 门店信息
						// this.ShopList = this.convertKeysToLowercase(res.Obj.FShop)
						// 店铺推荐商品
						let FGetHotGoodsBy = this.convertKeysToLowercase(res.Obj.FGetHotGoodsBy)
						if (FGetHotGoodsBy.length <= 4) {
							this.shopMerchandise = FGetHotGoodsBy;
						} else {
							this.shopMerchandise = FGetHotGoodsBy.slice(0, 4);
						}
						// 店铺规则 FParaConfig
						this.ruledetails = this.convertKeysToLowercase(res.Obj.FParaConfig)
						// 商品销售属性 FSaleAttributeList
						let Arr = [];
						let ArrID = [];
						let FSaleAttributeList = this.convertKeysToLowercase(res.Obj.FSaleAttributeList)
						for (let item of FSaleAttributeList) {
							for (let childitem of item.goodsClassPropertieDatas) {
								for (let pitem of this.listData.fPropertieDataIDS.split(',')) {
									if (pitem == childitem.fClassPropertieDataID) {
										childitem.checked = true;
										Arr.push(childitem.fClassPropertieData);
										ArrID.push(childitem.fClassPropertieDataID);
									}
								}
							}
						}
						this.saleAttributeList = FSaleAttributeList;
						this.attributeGoods = ArrID; //判断商品是否存在如不存在则显示无货接口除了其他属性以外属性参数数组
						// 厂家，规格，适用范围，售后保障 GoodsDetailes
						let GoodsDetailes = this.convertKeysToLowercase(res.Obj.FGoods.GoodsDetailes)
						if (GoodsDetailes.length <= 0) {

						} else {
							GoodsDetailes.forEach((item, i) => {
								if (i == 0) {
									item.checked = true;
									this.content = item.fContent;
								} else {
									item.checked = false;
								}
							});
							this.tabData = GoodsDetailes;
							this.activeName = this.tabData[0].fGoodsDetailClassCode;
						}
						// 仓库信息
						let FDepotinfo = this.convertKeysToLowercase(res.Obj.FDepotinfo)
						this.warehouseAddress = FDepotinfo.fAddress;
						this.warehouseAddressLatAndLng = FDepotinfo;
						// 客服
						this.fRulObj = this.convertKeysToLowercase(res.Obj.FService)

					})
					.catch(err => {});
			},
			// 整合接口 店铺是否关注 商品是否收藏
			getAttentionCollection() {
				this.ApiRequestPostNOMessV7('/api/mall/other/focus-shop/get-attention-collection', {
						FGoodsID: this.fGoodsID
					}).then(res => {
						// 店铺是否关注
						this.focusOnQuantity = res.Obj.FOcusShop.Focus;
						if (res.Obj.FOcusShop.IsFocus == 0) {
							this.followShow = true;
						} else {
							this.followShow = false;
						}
						// 商品是否收藏
						this.collection = res.Obj.FIsavorite;
					})
					.catch(err => {});
			},
			// 整合接口  优惠券  限时送达  是否参与任务集市  当前店铺的积分规则
			getParticulars() {
				this.ApiRequestPostNOMessV7('/api/mall/activity/coupon/get-particulars', {
						FGoodsID: this.fGoodsID
					}).then(res => {
						// 优惠券
						if (res.Obj.FOutgoingparameter) {
							if (res.Obj.FOutgoingparameter.FIsGet) {
								this.couponShow = true;
								this.changeStyle = 'flex-row-space-between'
							} else {
								this.couponShow = false;
								this.changeStyle = 'anotherStyle'
							}
							let FOutgoingparameter = this.convertKeysToLowercase(res.Obj.FOutgoingparameter)
							if (FOutgoingparameter) { //有商品活动
								this.activityInfo = FOutgoingparameter
								let beforeshow = new Date(this.activityInfo.fRemindTime).getTime(); //活动开始展示时间
								let start = new Date(this.activityInfo.fBeginTime).getTime(); //活动开始时间
								let end = new Date(this.activityInfo.fEndTime).getTime(); //活动结束时间
								let now = new Date().getTime();
								if (this.activityInfo != null && this.activityInfo.fCouponRuleID == -1) {
									if (this.activityInfo.fIconPath != null && this.activityInfo.fIconPath != "") {
										this.hasShowAl = true
									} else {
										return;
									}

								} else {
									if (now < beforeshow) {
										//活动未开始
										this.hasActivity = false
										return;
									} else {
										this.hasActivity = true
										this.hasShowAl = true;
									}
									if (now >= beforeshow && now < start) {
										this.showactivitytext = "距离活动开始："
										this.usedTime = (start - now)
									}
									if (now > end) {
										this.hasActivity = false
										this.hasShowAl = false;
									}
									if (now >= start && now < end) {
										this.showactivitytext = "距离活动结束："
										this.usedTime = (end - now)
									}
								}
							} else {
								this.hasActivity = false
							}
						}
						// 限时送达
						let FLimitedDeliveryRule = this.convertKeysToLowercase(res.Obj.FLimitedDeliveryRule)
						if (FLimitedDeliveryRule.length == 0) {
							this.isShowActivity = false
						} else {
							this.isShowActivity = true
							this.tableData = FLimitedDeliveryRule
							this.tableData.forEach((item, index) => {
								item.activityTime = this.getTime(FLimitedDeliveryRule[index].fBeginTime) +
									'至' + this
									.getTime(FLimitedDeliveryRule[index].fEndTime)
								item.fBeginTime = this.getTime(item.fBeginTime)
								item.fEndTime = this.getTime(item.fEndTime)
								item.integral = FLimitedDeliveryRule[index].fPointAmount + '积分/' +  FLimitedDeliveryRule[index].fUnitNames
								if (item.fIsHd == 1) {
									item.fIsHdName = '汉德设备'
								} else if (item.fIsHd == 0) {
									item.fIsHdName = 'GPS设备'
								}
							})
						}
						// 是否参与任务集市
						this.CheckGoodsShow = res.Obj.FIres
					})
					.catch(err => {});
			},

			toTask() {
				this.$router.push('/Task')
			},
			//门店组件的返回事件
			shopListChange(val1, val2) {
				this.isThird = true
				//当门店变化后，需要再次调用接口！！！
				if (val1) {
					this.fGoodsID = val2
					this.getGoodsAttentionCollection(this.fGovCode)
					this.getParticulars()
				}
			},
			// 显示显示活动奖励对话框
			clickRadio(e, row) {
				if (e.target.tagName != 'INPUT') {
					return
				}
				if (this.radioIdSelect === row.fRuleID) {
					this.radioIdSelect = -1
					this.fDeliveryIDs = ''
					this.activeActivity = {}
				}
			},
			// 确认选中
			checkedActivity() {
				if (this.radioIdSelect === -1) {
					this.$message.warning('请选择一个活动奖励')
					return
				}
				this.radioActivity = this.activeActivity
				if (this.radioActivity.fIsHd == 0) {
					this.radioActivity.fIsHdTitle = "GPS设备"
				} else if (this.radioActivity.fIsHd == 1) {
					this.radioActivity.fIsHdTitle = "汉德设备"
				}
				this.isShowActivityDailog = false
			},
			// 取消选中
			uncheckedActivity() {
				this.radioIdSelect = -1
				this.radioActivity = this.activeActivity = {}
				this.isShowActivityDailog = false
			},
			showActivity() {
				this.isShowActivityDailog = true
			},
			handleSelectionChange(val) {
				this.activeActivity = val
				this.fDeliveryIDs = val.fDeliveryIDs
			},
			// 新增弹窗提交成功回调
			myMapTypeChange(val) {
				this.myMapTypeVisible = val;
			},
			// 显示地图
			showFencveInfo(row) {
				this.myMapTypeID = row.fAreaID
				this.myMapTypeVisible = true;
			},
			//领券弹窗里面按钮点击事件fReceiveStatus==0调用领取优惠券接口==1查看可购买商品
			receiveClick(fReceiveStatus, fCouponID) {
				if (fReceiveStatus == 0) {
					this.ApiRequestPostNOMess('api/mall/ebactivity/customerreceiverecord/create', {
						fCouponID: fCouponID
					}, ).then(res => {
						this.getParticulars()
					}, error => {

					});
				}
			},
			//小图左边三角点击事件
			leftImg() {
				if (this.showPicInx > 0 && this.showPicInx <= this.picurl.length) {
					this.showPicInx = this.showPicInx - 1;
					this.showPicUrl = this.picurl[this.showPicInx];
					if (
						this.showPicInx == 0 ||
						this.showPicInx == 1 ||
						this.showPicInx == 2 ||
						this.showPicInx == 3
					) {
						this.$refs.piclist.style.left = 38 + "px";
					} else {
						this.$refs.piclist.style.left =
							"-" + 125 * (this.showPicInx - 3) + "px";
					}
				}
			},
			//小图右边三角点击事件
			rightImg() {
				if (this.showPicInx >= 0 && this.showPicInx < this.picurl.length - 1) {
					this.showPicInx = this.showPicInx + 1;
					this.showPicUrl = this.picurl[this.showPicInx];
					if (this.showPicInx == 0 || this.showPicInx == 1 || this.showPicInx == 2 || this.showPicInx == 3) {
						this.$refs.piclist.style.left = 20 + "px";
					} else {
						this.$refs.piclist.style.left =
							"-" + 130 * (this.showPicInx - 3) + "px";
					}
				}
			},
			//图片点击事件
			imgClick(val, index) {
				this.showPicUrl = val;
				this.showPicInx = index;
			},
			//倒计时结束事件
			countDownEnd(val, index) {
				this.getParticulars()
			},
			//去领券中心
			toCouponCollection() {
				if (this.activityInfo.fShopUnitID == '-1') {
					this.$router.push('/CouponCollection')
				} else {
					this.$router.push({
						name: 'CouponCollectionShop',
						query: {
							id: this.listData.fShopUnitID
						}
					});
				}
			},
			//用户可对商品进行收藏和取消收藏
			star() {
				// 防止用户频繁点击导致的问题
				// var flag=true
				if (this.isCollection) {
					this.isCollection = false
					this.ApiRequestPostNOMess('api/mall/auxiliary/goods-collect/star', {
							id: this.fGoodsID
						})
						.then(result => {
								this.getAttentionCollection();
								setTimeout(() => {
									this.isCollection = true
								}, 1000)
							},
							rej => {}
						)
				}
			},
			//生产厂点击事件
			saleAttributeClick(val, val1) {
				for (let item of this.saleAttributeList) {
					if (item.fClassPropertieID == val) {
						for (let childitem of item.goodsClassPropertieDatas) {
							if (childitem.fClassPropertieDataID == val1) {
								childitem.checked = true;
							} else {
								childitem.checked = false;
							}
						}
					}
				}
				this.$forceUpdate();
				let attributes = [];
				for (let item of this.saleAttributeList) {
					for (let childitem of item.goodsClassPropertieDatas) {
						if (childitem.checked) {
							attributes.push(childitem.fClassPropertieDataID + '');
						}
					}
				}
				this.getAttributeGoods(attributes);
			},

			//根据包装方式查商品变了没
			getAttributeGoods(attributes) {
				this.ApiRequestPostNOMessV7('/api/mall/client/goods/get-attribute-goods', {
						FGoodsID: this.fGoodsID,
						Attributes: attributes
					})
					.then(result => {
							this.fGoodsID = result.Obj.FGoodsID;
							this.getGoodsAttentionCollection(this.fGovCode)
							this.getAttentionCollection()
							this.getParticulars()
						},
						rej => {}
					)
			},

			//其他属性点击事件
			otherAttributeClick(val, val1) {
				// let otherattributeID = '';
				for (let item of this.otherAttributeList) {
					if (item.fClassPropertieID == val) {
						item.detailed.forEach(item1 => {
							if (item1.fGoodsPropertieDataID == val1) {
								item1.checked = true;
							} else {
								item1.checked = false;
							}
						})
					}
				}
				this.$forceUpdate();
				// this.otherattributeID = otherattributeID; //判断商品是否存在如不存在则显示无货其他属性参数
			},
			//打开地图
			toMap(obj) {
				// let url = "https://apis.map.qq.com/uri/v1/geocoder?coord=" + obj.flat + "," + obj.flng + "&referer=" + config.TengxunMapKey;
				let url = "https://uri.amap.com/marker?position=" + obj.flng + "," + obj.flat + "&name=" + obj.fAddress
				window.open(url, "_black")
			},

			//计数器事件
			handleChange(value) {
				// this.numValue = value;
				// if (this.numValue % this.stepNumValue != 0 && this.stepNumValue != 0) {
				// 	this.numValue = (this.numValue / this.stepNumValue).toFixed(0) * this.stepNumValue;
				// }
				if (!this.numValue) {
					this.numValue = 0
				}
			},
			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},
			//加入购物车提示
			getTips() {
				const message = this.$message({
					dangerouslyUseHTMLString: true,
					type: 'success',
					showClose: true,
					duration: 1000,
					customClass: 'iconshopcar',
					message: `
					<span style="font-size:16px;color:#10C578;font-weight:bold">成功加入购物车！</span>
					<p style="margin-top:20px;color:#999999">您可以<a   href="#/shoppingCart" class="aselce" style="color:dodgerblue;cursor: pointer;">去购物车结算</a>，或<a class="aselc" href="#/Goods"  style="color:dodgerblue;cursor: pointer;">返回列表</a></p>`
				});
				message.$el.querySelector('.aselc').onclick = () => {
					message.close();
				};
				message.$el.querySelector('.aselce').onclick = () => {
					message.close();
				};

			},
			// 没开通账服通时的提示语
			ACSTips() {

				const message = this.$message({
					dangerouslyUseHTMLString: true,
					type: 'success',
					showClose: true,
					duration: 1000,
					customClass: 'iconshopcar',
					message: `<p  style="color:#999999">亲，请先<a href="#/businessme/AccountPay" class="aselceACSTips" style="color:dodgerblue;cursor: pointer;">开通帐服通钱包</a></p>`

				});
				message.$el.querySelector('.aselceACSTips').onclick = () => {
					message.close();
				};
			},
			// 获取是否开通过账服通
			getAcs() {
				this.ApiRequestPostNOMess('/api/mall/ebbalance/customer-acs/get-my-ACS').then(
					result => {
						this.acsData = result.obj
						// 判断不是自营店时 
						if (this.listData.fShopTypeID != 1) {
							// 没有开通账服通
							if (this.acsData == null) {
								this.Addcart(); //调用加入购物车成功但不显示加入成功的弹窗
								this.$confirm('亲，请先开通帐服通钱包?', '提示', {
									confirmButtonText: '前往开通',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									sessionStorage.ApplicationName = '账服通钱包'
									this.$router.replace({
										path: '/businessme/AccountPay',
									});

								}).catch(() => {});

							} else {
								this.Addcart();
								this.getTips();
							}
						} else { //如果是自营店，
							this.Addcart(); //调用加入购物车接口
							this.getTips(); //弹出加入购物车成功的消息
						}

					})
			},
			//加入购物车成功但不显示加入成功的弹窗
			Addcart() {
				this.ApiRequestPostNOMess('api/mall/ebsale/shopping-cart/create-shopping-cart', {
						fPointPrice: 0,
						fGoodsPrice: 10,
						fAmount: this.numValue,
						fGoodsID: this.fGoodsID,
						fDeliveryID: 2
					})
					.then(result => {
							this.getCarCuont();
						},
						rej => {}
					)
			},
			//加入购物车
			addToCart() {
				this.getAcs()
			},
			//获取购物车总数
			getCarCuont() {
				this.ApiRequestPostNOMess(
					"api/mall/ebsale/shopping-cart/get-shopping-cart-count"
				).then(
					(result) => {
						this.$store.commit("setCarCount", result.obj.number);
					},
					(rej) => {}
				);
			},
			//立即购买
			async buyNowClick(val) {
				if (val.fStatus == 2) {
					this.$message('亲，当前商品已售罄，无法购买');
					return
				}
				// 非自营店时
				if (this.listData.fShopTypeID != 1) {
					this.ApiRequestPostNOMess('/api/mall/ebbalance/customer-acs/get-my-ACS').then(
						result => {
							this.acsDataimmediately = result.obj
							if (this.acsDataimmediately == null) {
								this.$confirm('亲，请先开通帐服通钱包?', '提示', {
									confirmButtonText: '前往开通',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									sessionStorage.ApplicationName = '账服通钱包'
									this.$router.replace({
										path: '/businessme/AccountPay',
									});

								}).catch(() => {});

							} else {
								let obj = [];
								let data = {
									fGoodsID: this.fGoodsID,
									fDeliveryID: 2,
									fShopUnitID: this.listData.fShopUnitID,
									fAmount: this.numValue
								};
								obj.push(data);
								this.$store.commit("changeCommonData", obj);
								this.$router.push({
									name: 'FillOrder',
									query: {
										id: 1, //1是商品详情跳转，2是购物车跳转，3是订单跳转
										radioIdSelects: JSON.stringify([this.radioIdSelect]),
										fDeliveryIDs: JSON.stringify([this.fDeliveryIDs])
									}
								})
							}
						})
				} else { //如果是自营店，
					let obj = [];
					let data = {
						fGoodsID: this.fGoodsID,
						fDeliveryID: 2,
						fShopUnitID: this.listData.fShopUnitID,
						fAmount: this.numValue
					};
					obj.push(data);
					this.$store.commit("changeCommonData", obj);
					this.$router.push({
						name: 'FillOrder',
						query: {
							id: 1, //1是商品详情跳转，2是购物车跳转，3是订单跳转
							radioIdSelects: JSON.stringify([this.radioIdSelect]),
							fDeliveryIDs: JSON.stringify([this.fDeliveryIDs])
						}
					})
				}
			},
			//关注点击事件
			followCreateClick() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/focus-shop/create', {
						fShopUnitID: this.listData.fShopUnitID
					})
					.then(result => {
							this.getAttentionCollection();
							this.$message({
								message: '关注店铺成功！',
								type: 'success'
							});
						},
						rej => {}
					)
			},
			//取消关注事件
			followDeleteClick() {
				this.ApiRequestPostNOMess('api/mall/auxiliary/focus-shop/delete', {
						fShopUnitID: this.listData.fShopUnitID
					})
					.then(result => {
							this.getAttentionCollection()
						},
						rej => {}
					)
			},
			//联系客服
			contactServiceClick() {
				// var element = document.createElement("script");
				// element.src = "kefu.cckefu3.com/vclient/?webid=144845&wc=2d5b11";
				// document.body.appendChild(element);
				window.open(this.fRulObj.fRul, '_blank')
			},
			//进店
			enterTheStore(id) {
				this.$router.push({
					name: 'storeList',
					query: {
						id: id
					}
				})
			},
			//点击热销商品根据id重新加载页面
			hotGoods(obj) {
				window.scrollTo(0, 0);
				this.fGoodsID = obj.goods.fGoodsID;
				this.getParticulars()
				this.getGoodsAttentionCollection(this.fGovCode)
			},
			//tab点击事件
			handleClick(tab, event) {},


			// 新增验证事件
			submitForm() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						// this.addSubmit()
					} else {
						return false
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.el-message__closeBtn {
		fonr-size: 40px;
	}

	/deep/.el-message.is-closable .el-message__content {
		padding-left: 16px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.top-box {
		min-height: 488px;
	}

	.box-left {
		display: flex;
		flex-direction: column;

		.outline-imgs {
			width: 349px;
			height: 349px;

			&::v-deep img {
				width: 100%;
				height: 100%;
			}

			.outline-img {
				width: 325px;
				height: 325px;
				border: 1px solid #f2f2f2;
				padding: 10px;
				border-radius: 10px;

				&::v-deep img {
					width: 100%;
					height: 100%;
					border-radius: 10px;
				}
			}

			.activity-img-box {
				width: 50%;
				height: 50%;
			}

			.activityImg {
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}

			.outline-piclist {
				width: 307px;
				height: 80px;
				overflow: hidden;
				margin: 10px 0;
				position: relative;
				padding: 0 20px;

				.piclist-box {
					cursor: pointer;
					position: absolute;
					left: 20px;
					top: 0;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
				}

				.left-arrow {
					z-index: 3;
					width: 27px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background-color: #333333;
					opacity: 0.1;
					font-size: 20px;
					color: #fff;
					position: absolute;
					left: 0;
					top: 0;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					&:hover {
						opacity: 0.7;
						transition-property: opacity;
						transition-duration: 0.3s;
					}
				}

				.right-arrow {
					z-index: 3;
					width: 27px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					background-color: #333333;
					opacity: 0.1;
					font-size: 20px;
					color: #fff;
					position: absolute;
					right: 0;
					top: 0;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					&:hover {
						opacity: 0.7;
						transition-property: opacity;
						transition-duration: 0.3s;
					}
				}

				.piclist-img {
					width: 78px;
					height: 78px;
					margin-right: 10px;
					border: 1px solid #f2f2f2;

					img {
						width: 78px;
						height: 78px;
					}

					&:hover,
					&.active {
						border: 1px solid #f03333;
					}
				}
			}
		}
	}

	.tag-box {
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.activity-box {
		width: 730px;
		height: 40px;
	}

	.box-right {}

	.commodityInformation-box {
		width: 704px;
	}

	.dashed {
		border-right: 1px dashed #FA2C60;
		margin: 0 2px;
	}

	.receive-box {
		border-bottom: 1px dashed #FA2C60;
	}

	.factory-box {
		min-width: 95px;
		height: 24px;
		line-height: 24px;
		border-radius: 5px;
	}

	.bcgEAF3FF {
		background-color: #EAF3FF;
	}

	.factory-hover:hover {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.address-box {
		height: 46px;
		border: 1px dashed #E1edff;
		line-height: 40px;
		border-radius: 10px;
	}

	.tatle-box {
		width: 65px;
	}

	.btn-box {
		width: 235px;
		height: 45px;
		line-height: 45px;
		box-sizing: border-box;
	}

	.btn-box1 {
		width: 725px;
		height: 45px;
		line-height: 45px;
		box-sizing: border-box;
	}

	.b-box-left {
		width: 260px;
		height: auto;
		box-sizing: border-box;
	}

	.img-box {
		width: 45px;
		height: 45px;
		box-sizing: border-box;
	}

	.img-style {
		width: 35px;
		height: 35px;
	}

	.follow-box {
		width: 60px;
		height: 24px;
		line-height: 24px;
	}

	.shopStarRating-box {
		width: 150px;
		height: 22px;
		line-height: 22px;
		box-sizing: border-box;
	}

	.el-rate::v-deep {
		height: 15px;

		.el-rate__icon {
			margin-right: 0;
			font-size: 15px;
		}
	}

	.shopBtn-box {
		width: 110px;
		height: 30px;
		line-height: 28px;
		box-sizing: border-box;
	}

	.left-middle {
		padding: 17px;
		background-image: linear-gradient(to bottom, #9FC8FF, #D0F3FF);
		border-radius: 10px;
	}

	.b-box-right {
		width: 820px;
		min-height: 521px;
		height: auto;
	}

	.img-box1 {
		width: 107px;
		height: 107px;
	}

	.img-style1 {
		width: 90px;
		height: 90px;
	}

	.title-box {
		width: 107px;
	}

	.el-tabs--border-card::v-deep {
		background: #FFF;
		border: none;
		box-shadow: none;

		.el-tabs__header {
			background-color: #F8F9FE;
			border: 1px solid #f2f2f2;
			margin: 0;
		}

		.el-tabs__item:hover {
			@include themify($themes) {
				color: themed('themes_color');
			}
		}

		.is-active {
			@include themify($themes) {
				color: themed('themes_color');
			}

			background-color: #FFF;
			border-right-color: #f2f2f2;
			border-left-color: #f2f2f2;
		}

		.el-tabs__content {
			padding: 10px 0;
		}
	}

	.evaluateTitle-box {
		height: 63px;
	}

	.img-box2 {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.img-style2 {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}

	.whole {
		width: 65px;
		height: 24px;
		line-height: 24px;
	}

	.evaluate-btn {
		height: 24px;
		line-height: 24px;
	}

	.shopRulesDialog::v-deep {
		.el-dialog__body {
			padding: 10px 20px 20px 20px;
		}
	}

	.el-input-number--mini::v-deep {
		line-height: 36px;
	}

	.cub {
		color: dodgerblue;
		cursor: pointer;
	}

	.iconshopcar {
		.el-message {
			.el-icon-success {
				font-size: 32px !important;
			}
		}
	}

	.iconTopLeft {
		top: 0;
		left: 0;
	}

	.iconTopRight {
		top: 0;
		right: 0;
	}


	.iconBottomRight {
		bottom: 0;
		right: 0;
	}

	.iconBottomLeft {
		bottom: 0;
		left: 0;
	}

	.delete-btn {
		top: 7px;
		right: 7px;
		z-index: 1000;
		color: #9e9e9e;
	}

	.play-img {
		width: 50px;
		height: 50px;
		background-image: url(../../../assets/imgs/goods/play1.png);

		&:hover {
			cursor: pointer;
			background-image: url(../../../assets/imgs/goods/play2.png);
		}
	}

	.anotherStyle {
		float: right
	}

	.box-sellout {
		min-width: 70px;
		width: 100px;
		height: 100px;
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 100;
		padding: 0 10px;
		line-height: 24px;
	}

	.activity {
		padding-bottom: 20px;

		.tit {
			color: red;
			font-weight: 700;
			display: inline-block;
			margin-left: 17px;
		}

		.btn {
			// float: left;
			color: #0173FE;
			cursor: pointer;
			display: inline-block;
			margin-left: 15px;
		}

		.activityInfo {
			padding: 10px 17px;

			span {
				color: #0173FE;
			}
		}
	}

	.fence {
		text-decoration: underline;
	}

	/deep/.el-radio__label {
		padding: 0;
	}

	.map {
		::v-deep .el-dialog__body {
			padding: 0;
		}
	}

	.OrderText {
		line-height: 35px;
		color: #FF3333;
	}

	a:hover {
		border-bottom: 1px solid #0173FE;
	}

	::v-deep .el-dialog__body {
		padding: 10px 20px 20px 20px;
	}

	.img {
		background: url('../../../assets/car.png') no-repeat;
		background-size: 100% 100%;
		width: 20px;
		height: 20px;
		display: inline-block;
		vertical-align: middle;
	}

	.StandardBcgBox {
		background-color: #E1EDFF;
		border-radius: 10px;
	}

	.activityBagBox {
		background-color: #E1251A;
		border-radius: 0 0 10px 10px;
	}

	/deep/.el-input__inner {
		height: 36px !important;
		line-height: 36px !important;
		border: 1px solid #DCDFE6 !important;
	}

	/deep/.el-input-number {
		line-height: 34px !important;
	}
</style>