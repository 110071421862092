<template>
	<div class="shopList bg-FAFAFA" v-if="hasList" style="padding-bottom:5px;">
		<div v-if="hasList" class="font-size13  padding-t-10 margin-l-15" style="color:#000000; font-weight: 800;">当前店铺
		</div>
		<div v-if="hasList" class="shopInfo font-color-999">
			<div class="shopName">
				<span>{{shopInfo.FShopName}}</span>
				<span style="display:inin-block;margin-left:10px">联系方式：{{shopInfo.FOfficePhone}}</span>
				<span v-if="shopInfo.DistanceWeightValue !=0"
					style="padding-right: 30px; position: absolute; top: 0; right: 40px;">
					<span v-if="shopInfo.DistanceWeightValue <1000">{{'距离：' + shopInfo.DistanceWeightValue}}m</span>
					<span v-else>{{'距离：' + (shopInfo.DistanceWeightValue/1000).toFixed(2)}}Km</span>
				</span>
				<span class="pointer-color-theme" style="position: absolute; top: 0; right: 0; cursor: pointer;"
					@click="showShopList">更多店铺></span>
			</div>
			<!--    <div class="shopAddress">
        {{shopInfo.FAddress}}
      </div> -->
		</div>

		<!-- 门店列表 -->
		<el-dialog title="店铺列表" :visible.sync="showSelectList" width="60%" :close-on-click-modal="false"
			:close-on-press-escape="false">
			<!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
			<!-- <el-tab-pane label="附近店铺" name="first"> -->
			<el-table v-loading="treeLoading" :header-cell-style="{background:'#F8F9FE','text-align': 'center'}"
				:data="shopList" size="mini" border>
				<el-table-column label="选择" align="center" fixed width="55">
					<template slot-scope="scope">
						<el-radio v-model="radioIdSelect" :label="scope.row.FShopUnitID"
							@change.native="handleSelectionChange(scope.row)"
							@click.native="clickRadio($event, scope.row)">{{str}}</el-radio>
					</template>
				</el-table-column>
				<el-table-column v-for="(item, index) in tableLabel" :key="index" :label="item.label" :prop="item.prop"
					:fixed="item.fixed" :align="item.align" :width="item.width">
					<template slot-scope="scope">
						<template v-if="item.prop == 'DistanceWeightValue'">
							<template v-if="scope.row[item.prop]==0">-</template>
							<template v-else>{{ (scope.row[item.prop]/1000).toFixed(2)}}</template>
						</template>
						<template v-else>
							{{ scope.row[item.prop]}}
						</template>
					</template>
				</el-table-column>
				<el-table-column label="查看地图" align="center" fixed="right" width="120">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="toMap(scope.row)">查看地图</el-button>
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="uncheckedShop">取消</el-button>
				<el-button type="primary" @click="checkedShop">确定</el-button>
			</span>
		</el-dialog>
	</div>

</template>

<script>
	// import selectShop from '@/Page/selectShop.vue'
	export default {
		props: {
			activitys: {
				//type限定子组件的类型，如果是数组就写arr，字段有特殊颜色，不然就是单词错了
				type: Array,
			},
			//商品id
			ShopGoodID: {
				type: [String, Number],
			},
			//商品详情带的店铺id
			needFShopUnitID: {
				type: [String, Number]
			}
		},
		components: {
			//  selectShop
		},
		data() {
			return {
				dataloading: false,
				str: '',
				shopInfo: {
					DistanceWeightValue: 0,
					FShopUnitID: "",
					FShopName: '',
					FAddress: '',
					FOfficePhone: ''
				},
				activeShop: {},
				radioIdSelect: -1,
				showSelectList: false, //控制门店列表弹框
				hasList: false,
				activeName: 'first',
				tableLabel: [{
						label: '店铺名称',
						width: '220',
						prop: 'FShopName',
						align: 'left',
						fixed: true,
						type: 'string'
					},
					{
						label: '仓库地址',
						width: 'auto',
						prop: 'FAddress',
						align: 'left',
						type: 'string',
						fixed: false,
					},
					{
						label: '联系方式',
						width: '120',
						prop: 'FOfficePhone',
						align: 'left',
						type: 'string',
						fixed: false,
					},
					{
						label: '距离（Km）',
						width: '120',
						prop: 'DistanceWeightValue',
						align: 'center',
						type: 'Number',
						fixed: false,
					}
				],
				shopList: [], //门店信息
				treeInitialData: '',
				treeData: '',
				treeLoading: false,
				flng: 0,
				flat: 0,
				fGovernmenAreaID: 0, //区域id
				fuzhiOnce: true //接口查询出数据，是否直接给门店信息的shopInfo对象赋值

			}
		},
		created() {
			console.error("created!")
		},
		mounted() {
			this.acquireIp()
			//商品详情返回过来的店铺id
			this.radioIdSelect = Number(this.needFShopUnitID)
		},
		watch: {
			//监听商品id变化
			ShopGoodID(newVal, oldVal) {
				this.fuzhiOnce = true
				this.getShopList()
			}
		},
		methods: {
			showPosition(position) {
			    const latitude = position.coords.latitude;
			    const longitude = position.coords.longitude;
			    console.log("Latitude: " + latitude);
			    console.log("Longitude: " + longitude);
			  },
			//获取当前浏览器的类型
			myBrowser() {
				var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
				var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
				var isIE = userAgent.indexOf("compatible") > -1 &&
					userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
				var isEdge = userAgent.indexOf("Edg") > -1; //判断是否IE的Edge浏览器
				var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
				var isSafari = userAgent.indexOf("Safari") > -1 &&
					userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
				var isChrome = userAgent.indexOf("Chrome") > -1 &&
					userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
				if (isIE) {
					var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
					reIE.test(userAgent);
					var fIEVersion = parseFloat(RegExp["$1"]);
					if (fIEVersion == 7) {
						return "IE7";
					} else if (fIEVersion == 8) {
						return "IE8";
					} else if (fIEVersion == 9) {
						return "IE9";
					} else if (fIEVersion == 10) {
						return "IE10";
					} else if (fIEVersion == 11) {
						return "IE11";
					} else {
						return "0";
					} //IE版本过低
					return "IE";
				}
				if (isOpera) {
					return "Opera";
				}
				if (isEdge) {
					return "Edge";
				}
				if (isFF) {
					return "FF";
				}
				if (isSafari) {
					return "Safari";
				}
				if (isChrome) {
					return "Chrome";
				}
			},
			//浏览器ip定位
			acquireIp() {
				if (this.myBrowser() == "Chrome") {
					this.getShopList()
				} else {
					let that = this
					this.geolocation = new AMap.Geolocation({
						enableHighAccuracy: true, // 是否使用高精度定位，默认:true
						timeout: 10000, // 超过10秒后停止定位，默认：无穷大
						maximumAge: 0, // 定位结果缓存0毫秒，默认：0
						convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
						showButton: true, // 显示定位按钮，默认：true
						buttonPosition: 'LB', // 定位按钮停靠位置，默认：'LB'，左下角
						buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
						showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
						showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
						panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
						zoomToAccuracy: true // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
					});
					this.geolocation.getCurrentPosition(function(status, result) {
						if (status == 'complete') {
							//当高德地图拿到经纬度时
							that.flng = result.position.lng
							that.flat = result.position.lat
							that.getShopList()
						} else {
							//当高德地图没拿到经纬度时
							that.getShopList()
						}
					});
				}
			},
			//获取门店信息的数据
			getShopList() {
				if (this.activeName === "second" && this.fGovernmenAreaID == 0) {
					this.$message({
						type: 'warning',
						message: "请选择区域后进行查询！"
					});
					return
				}
				this.ApiRequestPostNOMess('/api/mall/goods/goods/get-list-by-goodsid', {
					"fGoodsID": Number(this.ShopGoodID),
					"fGovernmenAreaID": Number(this.fGovernmenAreaID),
					"flng": this.flng,
					"flat": this.flat
				}).then(res => {
					if (this.fuzhiOnce) {
						this.shopInfo.FShopUnitID = ""
						this.shopInfo.FShopName = ""
						this.shopInfo.FAddress = ""
						this.shopInfo.DistanceWeightValue = 0
						this.shopInfo.FOfficePhone = ""
					}
					//门店数据表格赋值
					this.shopList = JSON.parse(res.obj)
					console.log(this.shopList)
					if (this.shopList.length > 1) {
						this.hasList = true
						//给展示门店信息的shopInfo对象赋值
						if (this.fuzhiOnce) {
							for (let item of this.shopList) {
								if (item.FShopUnitID == this.needFShopUnitID) {
									this.shopInfo.FShopUnitID = item.FShopUnitID
									this.shopInfo.FShopName = item.FShopName
									this.shopInfo.FAddress = item.FAddress
									this.shopInfo.FOfficePhone = item.FOfficePhone
									this.shopInfo.DistanceWeightValue = item.DistanceWeightValue
									this.fuzhiOnce = false
								}
							}
						}
						//给选中的数据activeShop对象赋值
						for (let item of this.shopList) {
							if (item.FShopUnitID == this.needFShopUnitID) {
								this.activeShop = JSON.parse(JSON.stringify(item))
							}
						}
					} else {
						this.hasList = false
					}
				})
			},
			// 显示弹框（点击更多）
			showShopList() {
				this.showSelectList = true
				if (this.activeName === "first") {
					this.fGovernmenAreaID = 0
					this.getShopList()
				}
			},
			// 跳转地图
			toMap(obj) {
				console.log(obj)
				obj.lngStart = this.flng
				obj.latStart = this.flat
				let url = "https://uri.amap.com/marker?position=" + obj.FLNG + "," + obj.FLAT + "&name=" + obj.FShopName
				//        let url = "https://uri.amap.com/navigation?&from=" + obj.lngStart + "," + obj.latStart + "&to=" + obj.FLNG + "," + obj.FLAT
				// + "," + obj.FShopName
				console.log('obj', url);
				window.open(url, "_black")
			},
			//门店展示表格选中事件
			handleSelectionChange(val) {
				//给选中的对象activeShop赋值
				this.activeShop = val
			},
			clickRadio(e, row) {
				// if(e.target.tagName != 'INPUT'){
				//   return
				// }
				// if (this.radioIdSelect === row.fRuleID) {
				//   this.radioIdSelect = -1
				//   this.radioIdSelects[this.index] = -1
				// }
			},
			//点击确定的按钮
			checkedShop() {
				//ischangeShow判断门店是否更改
				let ischangeShow = false
				if (this.activeShop.FShopUnitID != undefined && this.activeShop.FShopUnitID != this.shopInfo.FShopUnitID) {
					ischangeShow = true
				}
				this.showSelectList = false
				if (this.activeShop.FShopUnitID != undefined) {
					this.shopInfo = JSON.parse(JSON.stringify(this.activeShop))
				}
				this.$emit('change', ischangeShow, this.activeShop.FGoodsID)
			},
			//点击取消按钮
			uncheckedShop() {
				this.showSelectList = false
				this.radioIdSelect = this.shopInfo.FShopUnitID
				this.activeName = "first"
			},
			//tab栏切换事件
			handleClick(tab, event) {
				console.log(this.activeName);
				if (this.activeName == "first") {
					this.fGovernmenAreaID = 0
					this.getShopList()
				}
				this.shopList = []
			},
			//更多门店的区域选择事假
			handleChange(value) {
				console.error(value, "value这还是")
				if (value === undefined) {
					this.fGovernmenAreaID = 0
					return
				}
				this.fGovernmenAreaID = value[value.length - 1]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.shopList {
		margin-bottom: 20px;

		.el-cascader {
			margin-bottom: 10px;
		}

		.shopInfo {
			margin-left: 15px;
			margin-right: 15px;

			span {
				line-height: 25px;
				height: 25px;
			}

			.shopName {
				position: relative;
			}

			.shopAddress {
				line-height: 25px;
			}
		}
	}
</style>